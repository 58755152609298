html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

/**font-family:  Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
**/
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}



.row {
  display: flex;
}

.column {
  flex: 50%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.org-sidebar {
  top: 32px;
  left: 64px;
  width: 100%;
  height: 100%;
}

.grid-wrapper-single {
  display: grid;
  grid-template-columns: repeat(1,minmax(0, 1fr));
  column-gap: 10px;
  margin-right: 45px;
  }


@media screen and (width < 1148px) {
  .buttons-pg {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(1,minmax(0, 1fr));
    column-gap: 10px;
    margin-right: 25px;
    margin-left: -20px;
    }
  }

@media screen and (width >= 1148px) {
  .buttons-pg {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  @supports (grid-template-rows: masonry) {
    .grid-wrapper {
      display: grid;
      grid-template-columns: repeat(2,minmax(0, 1fr));
      grid-template-rows: masonry;
      column-gap: 10px;
      margin-right: 45px;
    }
  }
  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2,minmax(0, 1fr));
    column-gap: 10px;
    margin-right: 45px;
  }
}

@media screen and (width < 1207px) {
  .grid-wrapper-policy-buttons {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 5px;
    column-gap: 10px;
  }

  .grid-wrapper-single {
    display: grid;
    grid-template-columns: repeat(1,minmax(0, 1fr));
    column-gap: 10px;
    margin-right: 25px;
    margin-left: -20px;
    }

  .pg-policy-buttons-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 5px;
    column-gap: 5px;
  }

  .pg-docs-buttons-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 5px;
    column-gap: 5px;
  }

  .pg-alerts-info-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 10px;
    column-gap: 10px;
    max-width: 800px
  }

  .pg-policy-buttons {
    min-width: 10vh;
    min-height: 8vh;
  }

  .pg-docs-buttons {
    min-width: 10vh;
    min-height: 8vh;
  }

  .pg-cors-policy-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 10px;
    column-gap: 10px;
  }
}

@media screen and (width >= 1207px) {
  .grid-wrapper-policy-buttons {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: 10px;
    
  }

  .pg-policy-buttons-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 10px;
    column-gap: 10px;
  }

  .pg-docs-buttons-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    row-gap: 10px;
    column-gap: 10px;
  }

  .pg-alerts-info-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 10px;
    column-gap: 10px;
    max-width: 800px
  }

  .pg-policy-buttons {
    min-width: 10vh;
    min-height: 15vh;
  }

  .pg-docs-buttons {
    min-width: 10vh;
    max-height: 16vh;
    min-height: 8vh;
  }

  .pg-cors-policy-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 10px;
    column-gap: 10px;
  }
}
@media screen and (width <= 767px){
  .table-text {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .account-page-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

}

@media screen and (width > 767px){
  .table-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .account-page-text {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  
}

.grid-wrapper-policy {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 50px;
  row-gap: 20px;
}

.loading-wrapper {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-loading-wrapper {
  height: 10px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-page-wrapper {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pg-max-height-45 {
  max-height: 45vh;
}

.pg-max-height-50 {
  max-height: 50vh;
}

.pg-max-height-55 {
  max-height: 55vh;
}

.pg-max-height-60 {
  max-height: 60vh;
}

.pg-max-width-600 {
  max-width: 600px;
}

.pg-min-width-200 {
  min-width: 200px;
}

.pg-max-width-180 {
  max-width: 180px;
}

.pg-max-height-300px {
  max-height: 300px;
}


.grid-wrapper-filter-radios {
  display: grid;
  grid-template-columns: repeat(4, minmax(20px,100px));
  column-gap: 1px;
}

.width-frame-1280 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  align-self: center;
}

.width-frame-720 {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  align-self: center;
}

.pg-policy-card-wrapper {
  justify-content: center;
  display: grid;
  align-self: center;
}

.grid-wrapper-update-plan-invoice {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: minmax(170px,2000px) minmax(100px,1200px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  column-gap: 10px;
}

.pg-buttons {
  min-height: 10px;
  min-width: 60px;
}

.pg-alert-card-buttons:hover {
  border-color: rgba(14, 165, 233);
  border-width: 1px;
}

.grow { 
  transition: all .2s ease-in-out; 
  }

.grow:hover { 
  transform: scale(1.01); 
  }

.navbar-dropdown {
  margin-left: -7rem;
  margin-top: 0.1rem;
  min-width: 10rem;
}

.navbar-dropdown-organization {
  margin-left: -9rem;
  margin-top: 0.1rem;
  min-width: 10rem;
}

.navbar-icons-div {
  transition: transform .2s
}

.navbar-icons-div:hover {
  color: white;
  transform: scale(1.1);
}

.navbar-icons {
  display: flex;
  align-content: center;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 10px;
}


@media screen and (width >= 600px) and (width <= 900px){
  .sidebar-margins{
    margin-left: 3.5rem;
  }

  .sidebar {
    transform: translateX(0);
    width: 3.5rem;
    z-index: 10;
  }

  .sidebar-icons-div {
    display: flex;
    align-items: flex-end;
    min-height: 2rem;
    max-height: 4rem;
    opacity: 50;
    color: rgba(148, 163, 184);
    transition: transform .5s
  }

  .sidebar-icons-div:hover {
    border-bottom: 1px;
    opacity: 100;
    color: white;
    transform: scale(1.3);
  }
  
  .sidebar-icons {
    display: flex;
    align-content: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 12px;
  }

  .brand-image {
    position: relative;
    display: flex;
    width: 32px;
    height: 32px;
  }

  .brand-text {
    display: none;
  }

  .sidebar-mid {
    display: flex;
  }

  .sidebar-large {
    display: none
  }

  .reports-charts-div {
    width: 100%;
  }

  .reports-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    column-gap: 10px
  }
}

@media screen and (width > 900px){
  .sidebar-margins{
    margin-left: 16rem;
  }

  .sidebar {
    transform: translateX(0);
    width: 16rem;
    z-index: 10;
  }

  .sidebar-icons-div {
    display: flex;
    align-items: flex-end;
    max-height: 2rem;
    opacity: 50;
    color: rgba(148, 163, 184);
    justify-content: flex-end;
    transition: transform .5s
  }

  .sidebar-icons-div:hover {
    border-bottom: 1px;
    opacity: 100;
    color: white;
    transform: scale(1.03);
  }
  
  .sidebar-icons {
    display: flex;
    align-content: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 12px;
  }

  .brand-image {
    position: relative;
    display: flex;
    width: 64px;
    height: 64px;
  }

  .brand-text {
    display: block;
  }

  .sidebar-mid {
    display: none;
  }

  .sidebar-large {
    display: flex;
  }

  .reports-charts-div {
    width: 100%;
  }

  .reports-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    column-gap: 15px
  }

  .policy-menu-grid-wrapper {
    display: grid;
    grid-template-columns: 75% 25%;
    column-gap: 10px
  }
}

@media screen and (width < 600px){
  .sidebar-margins{
    margin-left: 0rem;
  }

  .sidebar {
    transform: translateX(-100%);
    width: 1rem;
  }

  .brand-text {
    display: none;
  }

  .navbar-small {
    display: flex;
  }

  .navbar-large {
    display: none;
  }

  .reports-charts-div {
    width: 100%;
  }

  .reports-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    column-gap: 10px
  }

  .policy-menu-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    column-gap: 10px
  }

  .reports-charts {
    max-width: 100%;
    max-height: 200px;
    display: flex; 
    justify-content: flex-start;
  }

  .grid-wrapper-policy-buttons {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 5px;
    column-gap: 10px;
  }  
}

@media screen and (width > 600px){
  .navbar-small {
    display: none;
  }

  .navbar-large {
    display: flex;
  }

  .reports-charts {
    max-width: 100%;
    height: 100%;
    max-height: 300px;
    display: flex; 
    justify-content: flex-start;
  }
}

.sidebar-active-bg {
  background-color: rgba(140, 152, 204, 0.164); 
}

.sidebar-items-grid {
  width: 45%;
  align-content: center;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 10px;
}

.report-button:hover {
  background-color: rgba(224, 242, 254, 1);
}

.progress-bar-div {
  width: 100%;
  background-color: rgba(140, 152, 204, 0.164);
}

.progress-bar {
  max-width: 100%;
  height: 25px;
  background-color: lightblue;
}

.active-reports-div {
  max-height: 160px;
  overflow-y: auto;
  
}

.top-reports-cycle-div {
  max-height: 160px;
}

.policy-additional-headers-div:hover {
  background-color: rgba(224, 242, 254, 1);
  border-color: rgba(14, 165, 233);
  border-width: 1px;
}

.menu-rounded-border-top {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.menu-rounded-border-bottom {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordeon-div:hover {
  background-color: rgba(14, 165, 233, 0.4);
}

.tooltip {
  max-width: 200px;
  text-transform: none;
}

.tooltip-text {
  max-width: inherit;
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-menu {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}

.margins-nav-menu {
  margin-top: -1.2rem;
  margin-bottom: 0.2rem;
  margin-left: 0rem;
}

.margins-nav-menu-small {
  margin-top: -1.3rem;
  margin-left: -0.9rem;
  margin-right: -0.8rem;
}

.margins-nav-menu-items {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 230px;
  margin-top: 0px;
}

.margins-nav-menu-items-small {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 230px;
  margin-top: 0px;
}

.nav-menu-grid-wrapper {
  display: grid;
  grid-template-columns: 75% 25%;
  column-gap: 0px;
}

.nav-menu-small-grid-wrapper {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  column-gap: 0px;
}

.nav-menu-items {
  width: 100%;
}

.nav-menu-bg {
  color: rgba(148, 163, 184);
  background-color: rgba(140, 152, 204, 0.164); 
}

.nav-menu-bg:hover {
  color: white;
  background-color: rgba(140, 152, 204, 0.328); 
}

.nav-menu-text {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}